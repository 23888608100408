module.exports = {
  records: {
    btn1: "查询",
  },

  base:{
    zwsj:'No data available',
    tp:'Vote',
    ytp:'Voted',
    bj:'Edit'
  },
  titles: {
    frist: "Homepage",
    second: "Introduction",
    three: "Procedure",
    four: "Panel",
    five: 'Requirements',
    six: 'Partnership',
    dl:'Login/Register',
    gsdt:'dynamics'
  },
  hint:{
    cc:'Exceeding the limit',
    xx:'Incomplete information',
    sctx:'Image size can not exceed 5MB',
    scgs:'Mp4 only',
    scdx:'The file can not exceed 1GB',
    sccg:'Successfully deleted',
    tpcg:'Vote successfully'
  },

  homePage:{
    dl:'Login',
    zc:'Register',
    wlhxshsjds:'Future Mars Life Design Competition',
    sczp:'Upload Works',
    yxcy:'Excellent Idea',
    tp:'Vote',
    sc:'Upload',
    jrkt:'Available today',
  },

  tableHeader:{
    cszp:'Entries',
    sczp:'Upload Works',
    grzx:'Individual center',
    zxzp:'Latest',
    rmzp:'Popular',
    search:'Enter the title of the work/author nickname',
    ts:'Tips: Each user can vote for a maximum of 3 works per day, with a limit of 1 vote per work. The vote cannot be cancelled or modified'
  },
  
  user:{
    gr:'Personal information',
    cg:'Drafts',
    sc:'Collection',
    tp:'Voting records',
    zp:'My work',
    xm:'Name',
    dh:'Telephone',
    dz:'Address',
    xxdz:'Detailed address',
    wxhq:'Get updates from wechat',
    sw:'Address for posting prize',
    tc:'Log out',
    qx:'Cancel',
    bc:'Save'
  },

  /**
   *Title of work
Artwork cover
Work upload
Video introduction
Work introduction
name
telephone
   */
  upload:{
    t1:'* Please read carefully before signing up',
    t2:'Entry instructions',
    t3:',If you need to modify the uploaded works, you can do so at',
    t4:'My work',
    t5:'Page editing',
    t6:'Please select the entry category',
    xyb:'Next',
    syb:'Back',
    qrtj:'Confirm',
    trzp:'Works of others',
    jxsc:'Continue uploading',
    zpmc:'Name ',
    zpfm:'Artwork cover',
    zpsc:'Work upload',
    spjs:'Video introduction',
    zpjs:'introduction',
    xm:'Name',
    sjh:'Telephone',
    h1:'1 sheet/no more than 5M',
    h2:'1-10 pieces/single picture is not larger than 5M',
    h3:'mp4/ Max 1G',
    h4:'No more than 800 words',
    h5:'Please enter your name',
    h6:'Please output the phone number',
    h7:'Please enter the title of your work',
    h8:'Detailed Introduction to the Work (Creative Concept)',
    gx:'Congratulations! Work submitted successfully',
  },

  detail:{
    xq:'Details of works',
    mc:'Name',
    lm:'Category',
    sj:'Submission time',
    ps:'Number of votes',
    pm:'Category ranking',
    zpm:'Overall ranking',
    tpts:'Voted for this work for',
    day:'days',
    sc:'Collect',
    qxsc:'Uncollect',
    tp:'Votes',
    ytp:'Voted',
    js:'Work introduction',
    gdzp:'More works',
    sczp:'Delete',
    bjzp:'Edit',
  },

  categraylm:{
    qb:'ALL',
    jz:'Architecture & Landscape',
    pm:'Graphic & Cultural Creativity',
    gy:'Industrial & Product',
    sz:'Digital Art & Animation',
    ty:'Experience & Interaction',
    gg:'Public Art & Branding',
    jzd:'Future Mars harmonious human settlement environment beyond imagination and continuous symbiosis ',
    pmd:'Future Mars cultural symbols / graphics breaking through the boundaries and facing the future',
    gyd:'Future Mars pioneer experimental pieces with ecological rationalization and sustainability',
    szd:'Digital virtual experience of the future Mars life with the combination of the real and virtual world',
    tyd:'Immersive and on-site feeling of future Mars culture and environmental experience',
    ggd:'Pioneering, open, innovative, and unique "Future Mars Scene Check-In Sign"'


  },


  wordclouds: {
    a: 'How to prevent cosmic radiation',
    b: 'Mars landing safety factor',
    c: 'Psychological problems of long-term interplanetary flight ',
    d: 'Space medical technology ',
    e: 'Energy supply ',
    f: 'Mars instant Messenger ',
    j: 'Mars resource utilization ',
    h: 'Automated interplanetary infrastructure equipment ',
    i: 'Space animal and plant culture ',
    g: 'Gravity simulation in cabin ',
    k: 'Water resources collection and recycling'
  },

  categray: {
    a: 'all'
  }

};