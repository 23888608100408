module.exports = {
  records: {
    btn1: "Search",
  },
  base:{
    zwsj:'暂无数据',
    tp:'投票',
    ytp:'今日已投',
    bj:'编辑'
  },

  hint:{
    cc:' 超出限制个数',
    xx:'信息填写不完整！',
    sctx:'上传头像图片大小不能超过 5MB!',
    scgs:'上传格式只能是 mp4 格式!',
    scdx:'上传大小不能超过 1GB!',
    sccg:'删除成功',
    tpcg:'投票成功',
  },
  titles: {
    frist: "首页",
    second: "大赛介绍",
    three: "大赛流程",
    four: "评审阵容",
    five: '参赛须知',
    six: '合作伙伴',
    dl:'登录/注册',
    gsdt:'公司动态'

  },

  homePage: {
    dl: '登录',
    zc: '注册',
    wlhxshsjds: '未来火星生活设计大赛',
    sczp: '上传作品',
    yxcy: '优秀创意',
    tp: '投票',
    sc: '上传',
    jrkt: '今日可投',
  },

  tableHeader: {
    cszp: '参赛作品',
    sczp: '上传作品',
    grzx: '个人中心',
    zxzp: '最新作品',
    rmzp: '热门作品',
    search: '输入作品名称/作者昵称',
    ts: '提示：每位用户每天最多可为3个作品投票，每个作品限1票，投票不可取消不可修改。'
  },

  user: {
    gr: '个人信息',
    cg: '草稿箱',
    sc: '我的收藏',
    tp: '投票记录',
    zp: '我的作品',
    xm: '姓名',
    dh: '电话',
    dz: '地址',
    xxdz: '详细地址',
    wxhq: '从微信获取更新',
    sw: '实物奖品邮寄地址',
    tc: '退出登录',
    qx:'取消修改',
    bc:'保存修改',
  },

  upload: {
    t1: '*报名前请仔细阅读',
    t2: '参赛须知',
    t3: '，上传后的作品如需修改，可在',
    t4: '我的作品',
    t5: '页面编辑修改',
    t6: '请选择作品参赛类目',
    xyb:'下一步',
    syb:'上一步',
    qrtj:'确认提交',
    trzp:'欣赏他人作品',
    jxsc:'继续上传作品',
    zpmc:'作品名称',
    zpfm:'作品封面',
    zpsc:'作品上传',
    spjs:'视频介绍',
    zpjs:'作品介绍',
    xm:'姓名',
    sjh:'手机号',
    h1:' 1张/不大于5M',
    h2:'1-10张/单张图片不大于5M',
    h3:'mp4/最大1G',
    h4:'不超过800字',
    h5:'请输入姓名',
    h6:'请输入手机号',
    h7:'请输入作品名称',
    h8:'作品详细介绍（创作理念）',
    gx:'恭喜！作品提交成功',
  },

  detail:{
    xq:'作品详情',
    mc:'作品名称',
    lm:'作品类目',
    sj:'提交时间',
    ps:'当前票数',
    pm:'类目排名',
    zpm:'总排名',
    tpts:'已为该作品投票',
    day:'天',
    sc:'收藏',
    qxsc:'取消收藏',
    tp:'投票',
    ytp:'已投票',
    js:'作品介绍',
    gdzp:'更多作品',
    sczp:'删除作品',
    bjzp:'编辑作品',
  },

  categraylm: {
    qb: '全部',
    jz: '建筑与景观设计',
    pm: '平面设计与文创IP设计',
    gy: '工业设计与产品设计',
    sz: '数字艺术与动画设计',
    ty: '体验设计与交互设计',
    gg: '公共艺术与品牌设计',
    jzd:'超越想象、持续共生的未来火星和谐人居环境',
    pmd:'突破边界、面向未来的未来火星文化符号/图景',
    gyd:'生态合理化、可持续化的未来火星先锋实验件',
    szd:'数实结合、虚实共生的未来火星生活虚拟体验',
    tyd:'沉浸式、临场感的未来火星文化和环境体验',
    ggd:'先锋开放、创新独特的未来火星场景打卡标识',

  },
  wordclouds: {
    a: '宇宙辐射如何预防 ',
    b: '火星着陆安全系数 ',
    c: '长期星际飞行的心理问题',
    d: '太空医疗技术 ',
    e: '能源供应 ',
    f: '火星即时通讯 ',
    j: '火星资源利用 ',
    h: '自动化星际基建设备 ',
    i: '太空动植物养殖 ',
    g: '舱内重力模拟 ',
    k: '水资源采集及循环利用 ',
  },

  categray: {
    a: '全部'
  }

};