<template>
    <div class="topview">
        <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">

                <img ondragstart="return false" class="topview-logo1" src="../../assets/mars/logo-pic@3x.png" alt="">
                <img ondragstart="return false" class="topview-logoText1" style="width: 120px;" src="../../assets/mars/logo文字@3x.png" alt="">
                <div class="topview-buy2" style="position: absolute; right: 25px;" @click="toBuy()">
                    {{$t('titles.dl') }}
                </div>
        </div>
       
        <!-- <div class="topview-ebtn" @click="languageClick()">
            <div class="topview-ebtn-c" :style="{ color: !language ? '#E45700' : '#fff' }">中文</div>
            <div class="topview-ebtn-c" style="margin-left: 4px;margin-right: 4px;">/</div>
            <div class="topview-ebtn-e" :style="{ color: language ? '#E45700' : '#fff' }">EN</div>
        </div>

        <el-popover trigger="hover">
            <div style="width: 240px; height: 240px">
                <img ondragstart="return false" :src="require('../../assets/logo.png')" alt style="width: 100%; height: 100%" />
            </div>
            <el-button class="topview-buy" slot="reference">购票</el-button>
        </el-popover> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            titleArr: [this.$i18n.t('titles.frist'), this.$i18n.t('titles.second'), this.$i18n.t('titles.three'), this.$i18n.t('titles.four')],
            selectIndex: 0,
            language: false, // 0 中文  1 英文
        }
    },

    methods: {
        titleClick(index) {
            this.selectIndex = index
            this.$emit('selectTable',index)
        },

        languageClick() {
            this.language = !this.language
            this.$emit('changeLang')
        },

        toBuy() {
            // location.href('https://d.weimob.com/d/1_11_n5h60t')
            // location.href = 'https://d.weimob.com/d/1_11_n5h7nm'
            window.open('https://open.weixin.qq.com/connect/qrconnect?appid=wxee731c0f9dc6ad53&redirect_uri=https://design2023.marsbase1.com/&response_type=code&scope=snsapi_login')
        }
    },

    mounted() {
        let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
        if (isZh === 'zh') {
            this.language = false
        } else {
            this.language = true
        }
    }
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'ZDY';
    src: url('../../assets/ttf/AlimamaShuHeiTi-Bold.ttf');
}

.topview {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;

    .topview-logo1 {
        width: 30px;
        height: 38px;
    }

    .topview-logoText1 {
        margin-left: 12px;
        font-family: 'ZDY';
        font-size: 22px;
        color: #fff;
    }

    .topview-table {
        display: flex;
        align-items: center;
        margin-left: 40px;
        margin-top: 10px;

        .topview-table-item {
            margin-left: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .topview-table-item-text {
                font-size: 16px;
                opacity: 0.8;
            }

            .topview-table-item-line {
                margin-top: 4px;
                height: 3px;
                width: 40px;
                border-radius: 2px;
                background-color: #E45700; // rgba(0, 0, 0, 0.01);
            }

            .normalColor {
                color: #fff;
            }

            .selectColor {
                color: #E45700;
            }
        }
    }

    .topview-ebtn {
        width: 91px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 100px;

        .topview-ebtn-c {
            font-size: 16px;
            color: #FFFFFF;
        }

        .topview-ebtn-e {
            font-size: 16px;
            color: #FFFFFF;
        }
    }

    .topview-buy2 {
        // width: 80px;
        // height: 30px;
        padding: 0px 10px;
        border-radius: 15px;
        background-color: rgba(228, 87, 0, 1);
        text-align: center;
        text-align: center;
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        position: absolute;
        border: 1px solid rgba(228, 87, 0, 1);
    }
}
</style>