<template>
    <div class="topview">
        <img ondragstart="return false" v-if="!language" @click="jumptoMars()" class="topview-logo" src="../../assets/mars/logo-pic@3x.png" alt="">
        <img ondragstart="return false" v-if="!language" @click="jumptoMars()" class="topview-logoText" style="width: 120px;" src="../../assets/mars/logo文字@3x.png" alt="">
        <img ondragstart="return false" v-if="language" @click="jumptoMars()" class="topview-logo" src="../../assets/mars/英文logo图片@3x.png" alt="">
        <img ondragstart="return false" v-if="language" @click="jumptoMars()" class="topview-logoText" style="width: 120px;" src="../../assets/mars/英文logo文字@3x.png" alt="">
        <div class="topview-table">
            <div class="topview-table-item" @click="titleClick(index)" v-for="(item, index) in titleArr" :key="index">
                <div class="topview-table-item-text" :class="selectIndex == index ? 'selectColor' : 'normalColor'">
                    {{ index == 0 ? $t("titles.frist") : index == 1 ? $t("titles.second") : index == 2 ? $t("titles.three")
                        : index == 3 ? $t("titles.four") : index == 4 ? $t("titles.five") : index == 5 ? $t("titles.six") : index == 6 ? $t("titles.gsdt") : ''
                    }}
                </div>
                <div class="topview-table-item-line"
                    :style="{ backgroundColor: selectIndex == index ? '#E45700' : 'rgba(0,0,0,0.01)' }"></div>
            </div>

            <!-- <img ondragstart="return false" style="width:26px ;height: 12px;margin-top: -10px;" src="../../assets/mars/hot@3x.png" alt=""> -->
        </div>


        <div class="topview-ebtn" @click="languageClick()">
            <div class="topview-ebtn-c" :style="{ color: !language ? '#E45700' : '#fff' }">中文</div>
            <div class="topview-ebtn-c" style="margin-left: 4px;margin-right: 4px;">/</div>
            <div class="topview-ebtn-e" :style="{ color: language ? '#E45700' : '#fff' }">EN</div>
        </div>

        <el-popover v-if="!userData" trigger="hover">
            <div style="">
                <wxlogin :appid="'wxee731c0f9dc6ad53'" scope="snsapi_login" :theme="'black'"
                    :redirect_uri="'https://design2023.marsbase1.com/#/'"
                    :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgew0KICBkaXNwbGF5OiBub25lOw0KfQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7DQogIGJvcmRlcjogbm9uZTsNCiAgd2lkdGg6IDIwMHB4Ow0KICBoZWlnaHQ6IDIwMHB4Ow0KfQ0KLmltcG93ZXJCb3ggLnN0YXR1c3sNCiAgZGlzcGxheTogbm9uZQ0KfQ=='">
                </wxlogin>
            </div>
            <el-button class="topview-buy" slot="reference">{{$t('titles.dl')}}</el-button>
        </el-popover>

        <div v-if="userData" style="position: absolute;right: 35px;display: flex; align-items: center;height: 50px;">

            <img ondragstart="return false" style="width: 30px; height: 30px;border-radius: 15px;" :src="userData.headImg" alt="">
            <el-popover  v-if="hidenMenu" trigger="hover">
                <div>
                    <el-menu style="border: none;"  background-color="#fff" text-color="#333333" :active-text-color="'#E45700'"
                     class="el-menu-demo " @select="handleSelect">
                        <el-menu-item   style="text-align: left;height: 30px;line-height: 30px;" :index="item.id"
                            v-for="(item, index) in menuList" :key="index">{{ item.value
                            }}</el-menu-item>
                    </el-menu>
                </div>


                <el-button
                    class="mline1" style="text-overflow: ellipsis;max-width: 130px; background-color: rgba(0, 0, 0, 0);border: none; margin-left: 15px;font-size: 16px;color: #fff;"
                    slot="reference">{{ userData.userName 
                    }}</el-button>

            </el-popover>

            <div v-else>
                <el-button
                  class="mline1"  style="text-overflow: ellipsis;max-width: 130px;background-color: rgba(0, 0, 0, 0);border: none; margin-left: 15px;font-size: 16px;color: #fff;"
                    slot="reference">{{ userData.userName 
                    }}</el-button>

            </div>

            
        </div>



    </div>
</template>

<script>
import wxlogin from "vue-wxlogin";
export default {
    components: {
        wxlogin
    },
    props:{
        hidenMenu:{
            type:Boolean,
            default:() => {
                return true
            }
        }
    },
    data() {
        return {
            titleArr: [this.$i18n.t('titles.frist'), this.$i18n.t('titles.second'), this.$i18n.t('titles.three'), this.$i18n.t('titles.four'), this.$i18n.t('titles.five'), this.$i18n.t('titles.six'), this.$i18n.t('titles.gsdt')],
            selectIndex: 0,
            language: false, // 0 中文  1 英文
            userData: null,
            activeIndex:1,
            menuList: [{ value: this.$i18n.t('user.gr'), id: '1' },
      { value: this.$i18n.t('user.cg'), id: '2' },
      { value: this.$i18n.t('user.sc'), id: '3', },
      { value: this.$i18n.t('user.tp'), id: '4' },
      { value: this.$i18n.t('user.zp'), id: '5' },
      { value: this.$i18n.t('user.tc'), id: '6' }
      ],

        }
    },

    methods: {
        titleClick(index) {
            if(index != 6){
                this.selectIndex = index
                 this.$emit('selectTable', index)
            }else{
                window.open('/#/newsroom')
            }
           
        },

        languageClick() {
            this.language = !this.language
            this.$emit('changeLang')
        },


        setIndex(e) {
            this.selectIndex = e
        },

        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            // 1个人信息  2草稿箱 3我的收藏 4投票记录 5我的作品 6退出登录
            this.$emit('menu', key)
        },

        jumptoMars(){
            window.open('https://www.marsbase1.com','')
        }   
     },

    mounted() {
        let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
        if (isZh === 'zh') {
            this.language = false
        } else {
            this.language = true
        }

        const userdata = localStorage.getItem('userData')
        this.userData = userdata ? JSON.parse(userdata) : ''
        console.log('topviewmouted',this.userData)
    }
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'ZDY';
    src: url('../../assets/ttf/AlimamaShuHeiTi-Bold.ttf');
}

.topview {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 82px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    z-index: 2;
    box-sizing: border-box;

    .topview-logo {
        margin-left: 5%;
        width: 30px;
        height: 38px;
    }

    .topview-logoText {
        margin-left: 12px;
        font-family: 'ZDY';
        font-size: 22px;
        color: #fff;
    }

    .topview-table {
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 10px;
        max-width: 750px;

        .topview-table-item {
            margin-left: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .topview-table-item-text {
                font-size: 16px;
                opacity: 0.8;
            }

            .topview-table-item-line {
                margin-top: 4px;
                height: 3px;
                width: 40px;
                border-radius: 2px;
                background-color: #E45700; // rgba(0, 0, 0, 0.01);
            }

            .normalColor {
                color: #fff;
            }

            .selectColor {
                color: #E45700;
            }
        }
    }

    .topview-ebtn {
        width: 91px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 230px;

        .topview-ebtn-c {
            font-size: 16px;
            color: #FFFFFF;
            line-height: 30px;
        }

        .topview-ebtn-e {
            font-size: 16px;
            color: #FFFFFF;
            line-height: 30px;
        }
    }

    .topview-buy {
        // width: 80px;
        height: 30px;
        border-radius: 15px;
        background-color: rgba(228, 87, 0, 1);
        text-align: center;
        text-align: center;
        color: #fff;
        font-size: 16px;
        line-height: 8px;
        position: absolute;
        right: 50px;
        margin-top: -15px;
        border: 1px solid rgba(228, 87, 0, 1);
    }
}


::v-deep cust  .el-menu {
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #FFF;
    border: none;
}

.mline1{
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>